.header {
    /* border: 1px solid #000000; Black border */
    display: flex;
    justify-content: space-between; 
    max-width: 1400px;
    margin: auto;
    padding: 0 7px;
    padding-top: 15px;
    padding-bottom: 15px;
    /* padding: 30px; */
    /* Other styling as needed */
  }

  @media screen and (max-width: 600px) {
    .header {
        padding: 15px; /* Reduce padding on smaller screens */
        /* Any other style adjustments for mobile */
    }
}
  
  /* Styling for navigation links */
  .header nav a {
    margin-right: 10px; /* Consistent spacing between links */
    text-decoration: none;
    color: inherit; /* Inherits color from parent, useful for theming */
  }

  .header h1 {
    margin: 0; /* Resets default margin */
    padding: 0px;
    /* font-size: 12px; */
    /* display: block; */
    /* Other styles */
}
  
