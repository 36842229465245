.grid-container {
  /* border: 1px solid #000000; Black border */
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  /* gap: 20px; */
  /* padding: 15px; */
}

.product-card {
  padding: 5px;
  /* border: 1px solid #ccc; adjust to see product card extent. */
  width: 100%; /* Adjust the width as necessary, or keep it responsive */
  height: auto;
  /* margin: 5px; Optional, for spacing around the card */
  /* overflow: hidden; Keeps everything neat */
  position: relative; /* Necessary for absolute positioning of the image container */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.product-card img {
  border: .1px solid;
  width: auto; /* Adjust image size */
  height: inherit;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .product-card img {
      /* border: .1px solid; */
      height: inherit;
      padding: 0px;
      margin-bottom: 0%;
  }
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.67%;
  height: 0; /* or you can set a fixed height */
  /* min-height: 200px; Example minimum height */
  /* overflow: hidden; */
} 

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.3s ease;
}

.primary-image {
  opacity: 1;
}

.hover-image {
  opacity: 0;
}

.image-container:hover .primary-image {
  opacity: 0;
}

.image-container:hover .hover-image {
  opacity: 1;
}