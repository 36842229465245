body, a, p, h1, nav, li, h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-align: left;
  /* padding: 50px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header nav a {
  margin-right: 5px;
  color: black;
}

