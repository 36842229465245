.about-container {
  width: 50%;      
  align-items: left;
  padding: 8px;
}

/* This will apply to screens smaller than 600px wide */
@media (max-width: 600px) {
  .about-container {
    width: 80%;
    padding: 13px;
  }
}

